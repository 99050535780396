<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <new-club-product />
          </div>
        </v-card-title>
        <v-card-title>
          Club Products
        </v-card-title>
        <v-data-table
          :items="clubProducts"
          :headers="clubProductHeaders"
        >
          <template v-slot:item.club="{item}">
            {{ clubNameById(item.club) }}
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="editClubProduct(item)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <template v-if="item.isActive == true">
                    <v-btn
                      color="danger"
                      dark
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="deleteClubProduct(item._id)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      color="success"
                      dark
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="activateProduct(item)"
                    >
                      <v-icon>mdi-restore</v-icon>
                    </v-btn>
                  </template>
                </template>
                <span>{{ (item.isActive) ? 'Delete' : 'Restore' }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import NewClubProduct from './NewClubProduct'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      NewClubProduct,
    },
    data () {
      return {
        clubProductHeaders: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Assigned Club',
            value: 'club',
          },
          {
            text: 'Action',
            value: 'action',
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['clubProducts', 'clubNameById']),
    },
    async created () {
      await this.getAllClubs()
      this.getClubProducts()
    },
    methods: {
      ...mapActions(['getClubProducts', 'deleteClubProduct', 'getAllClubs']),
      editClubProduct (clubProduct) {
        this.$router.push(`/admin/club-products/${clubProduct._id}`)
      },
    },
  }
</script>

<style>

</style>
