<template>
  <v-dialog
    v-model="newClubProductDialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        class="rounded ma-2"
        tile
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        New Club Product
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">New Club Product</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newClubProduct.name"
                label="Product Name"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newClubProduct.description"
                label="Product Description"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newClubProduct.club_price"
                label="Club Price"
                type="number"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="newClubProduct.club"
                :items="allClubs"
                item-text="name"
                item-value="_id"
                label="Assign Club"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="newClubProduct.product"
                :items="allProducts.filter(product => product.direct_product == true)"
                item-text="name"
                item-value="_id"
                label="Assign Direct Product"
                required
              />
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="newClubProduct.product_entry"
                sort-by="calories"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>Product Entry Items</v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-spacer />
                    <v-dialog
                      v-model="dialog"
                      max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New Item
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Product Entry</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="6"
                                md="4"
                              >
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="Entry Name"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                                md="4"
                              >
                                <v-text-field
                                  v-model="editedItem.prefix"
                                  label="prefix"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                                md="4"
                              >
                                <v-text-field
                                  v-model="editedItem.type"
                                  label="type"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="close"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="dialogDelete"
                      max-width="500px"
                    >
                      <v-card>
                        <v-card-title class="text-h5">
                          Are you sure you want to delete this item?
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDelete"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                          >
                            OK
                          </v-btn>
                          <v-spacer />
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="newClubProductDialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          @click="saveClubProduct"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'NewClubProduct',
    data: () => ({
      newClubProductDialog: false,
      dialog: false,
      dialogDelete: false,
      newClubProduct: {
        product_entry: [],
      },
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Prefix', value: 'prefix' },
        { text: 'Type', value: 'type' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        prefix: '',
        type: '',
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),
    computed: {
      ...mapGetters(['allClubs', 'allProducts']),
    },
    created () {
      this.getAllClubs()
      this.getAllProducts()
    },
    methods: {
      ...mapActions(['getAllClubs', 'getAllProducts', 'createClubProduct']),
      saveClubProduct () {
        this.newClubProduct.club_price = Math.floor(this.newClubProduct.club_price * 100)
        this.createClubProduct(this.newClubProduct)
        this.newClubProductDialog = false
      },
      editItem (item) {
        this.editedIndex = this.newClubProduct.product_entry.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.newClubProduct.product_entry.indexOf(item)
        console.log(this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.newClubProduct.product_entry.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        console.log(this.editedIndex)
        if (this.editedIndex === -1) {
          this.addNewEntriy()
          this.close()
          return
        }
        this.saveEditedEntriy()
        this.close()
      },
      addNewEntriy () {
        this.newClubProduct.product_entry.push(this.editedItem)
      },
      saveEditedEntriy () {
        this.newClubProduct.product_entry.splice(this.editedIndex, 1, this.editedItem)
      },
    },
  }
</script>

<style>

</style>
