var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('new-club-product')],1)]),_c('v-card-title',[_vm._v(" Club Products ")]),_c('v-data-table',{attrs:{"items":_vm.clubProducts,"headers":_vm.clubProductHeaders},scopedSlots:_vm._u([{key:"item.club",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.clubNameById(item.club))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.editClubProduct(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.isActive == true)?[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.deleteClubProduct(item._id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]:[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.activateProduct(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-restore")])],1)]]}}],null,true)},[_c('span',[_vm._v(_vm._s((item.isActive) ? 'Delete' : 'Restore'))])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }